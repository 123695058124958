@import url(https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz);
body {
  margin: 0;
  padding: 0;
}


body, input, button {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  line-height: 1.3;
  font-size: 18px;
  color: #2e2f3e;
}

h1 {
  color: #2e2f3e;
  font-size: 35px;
}

h3 {
  text-transform: uppercase;
}

p, li {
  font-size: 20px;
}

a {
  color: #006227;
  text-decoration: none;
}

a:hover {
  color: #6ed9a1;
}

.App {
  background-color: white;
}

.main {
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.button-green  {
  display: inline-block;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  background-color: #006227;
  padding: 6px 25px;
  border-radius: 10px;
  min-width: 135px;
  margin-top: 20px;
}

.button-long {
  width: 40%;
}

.button-hollow {
  background-color: transparent;
  border: 1.5px solid #515151;
  color:#515151;
  font-weight: 580;
}

.button-hollow:hover {
  border: 1.5px solid #6bb09e;
}

.button-green:hover {
  background-color: #6bb09e;
  color: white;
}

.button-plain {
  color: #006227;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.grey-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 30px;
  background-color: #eaeaea;
}

.box-seventy-percent {
  width: 70%;
  margin: 5% auto;
  padding: 50px 50px 65px 50px;
}

/* utility */
.margin-top {
  margin-top: 6%;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.font-smaller-uppercase {
  font-size: 16px;
  text-transform: uppercase;
}

.green {
  color: #006227;
}

.link-grey {
  color: inherit;
}

.link-grey:hover {
  color: #006227;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 45px;
  padding-left: 10px;
  font-family: inherit;
  font-size: 18px;
  border-radius: 3.5px;
  outline: none;
  border: 1px solid #999999;
  padding: 3px 0 3px 15px;
}

select option {
  color: #666;
}

.category {
  background-color: #eeca4e;
  padding: 4px 12px;
  border-radius: 10px;
  width: 100px;
  border: 1px solid #eeca4e;
  margin-top: -15px;
  text-transform: uppercase;
  font-size: 16px;
}

/* header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.header-nav > ul {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.header-nav > ul > li > a {
  display: block;
  border: 1px solid black;
  padding: 8px;
  margin: 10px;
  min-width: 70px;

  color: black;
  text-decoration: none;
  text-align: center;
}

.header-nav > ul > .main-nav-highlight > a {
  background-color: #006227;
  color: white;
}

.header-nav > ul > .main-nav-highlight > a:hover {
  background-color: #6bb09e;
  border: 1px solid #6bb09e;
}

/* needs fixing that active link is yellow */
.header-nav a:hover, .header-nav a:active {
  background-color: #eeca4e;
  border: 1px solid #eeca4e;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content ul {
  list-style-type: none;
  padding-left: 0;
}

.dropdown-content a,
.dropdown-content button {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 20px;
}

.dropdown-content button {
  box-shadow: none;
  border: none;
  background: none;
  width: 100%;
}

.dropdown-content a:hover,
.dropdown-content button:hover {
  background-color: #eeca4e;
  border: none;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.svg-inline--fa, .icon {
  margin: 0 10px;
}

.icon-wrapper {
  width: 18%;
  min-width: 55px;
  margin-bottom: 5px;
}

/* footer */
.footer-wrapper {
  background-color: #f2f2f2;
  padding: 30px;
  margin-top: 30px;
}

.footer {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  color: #8c8c8c;
}

.footer nav ul {
  list-style-type: none;
  padding: 0;
}

.footer a {
  color: #8c8c8c;
}

.footer a:hover {
  color: #006227;
}
/* page: home */
.home-banner-image {
  width: 95%;
  margin: 0 30px;
}

.home-text-wrapper {
  padding: 0 20% 0 20%;
  text-align: justify;
}

.home-cta-wrapper {
  display: flex;
  justify-content: space-evenly
}

.cta-box {
  min-width: 300px;
  width: 30%;
  text-align: center;
}

.cta-box > h3 {
  margin-top: 0;
  margin-bottom: 30px;
}

/* login page */

.full-width-box {
  width: 80%;
  padding: 30px;
  margin: 30px auto;
  background-color: #eaeaea;
  text-align: center;
}

input {
  width: 50%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 18px;
}

/* dashboard */
.dashboard-box {
  padding: 30px;
  margin: 5% 0;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dashboard-image {
  float: left;
  width: 30%;
  height: 30%;
}

.dashboard-text {
  align-content: flex-start;
}

.dashboard-box-details {
  display: flex;
  margin-bottom: 15px;
}

.dashboard-box-details .svg-inline--fa {
  margin: 0 30px;
}

.detail-wrapper {
    margin-top:20px;
    display: flex;
    justify-content: space-between;
}

.detail-text {
  width: 64%;
  text-align: justify;
  margin-bottom: 45px;
}

.details-img {
  width: 265px;
  margin-top: 25px;
}

.location-box {
  background-color: #f1f8ee;
  width: 265px;
  padding-bottom: 15px;
  margin-top: -6px;
}

.location-box > span {
  padding: 20px;
}

/* task detail page */

.organizer-box {
  text-align: justify;
  display: flex;
}

.organizer-box p {
  font-size: 18px;
}

.organizer-img-wrapper {
  margin: 35px 10px 0 50px;
  font-weight: bold;
  text-align: center;
}

.organizer-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 10px 0;
}

.progress-bar {
  position: relative;
  height: 18px;
  width: 80%;
  background-color:#9e9e9e;
  margin: 30px auto;
  text-align: center;
}

.filler {
  background: #eeca4e;
  height: 100%;
  border-radius: inherit;
  -webkit-transition: width .2s ease-in;
  transition: width .2s ease-in;
}

.margin-right {
  margin-right: 30px;
}

.warning-box {
  background-color: #faf1d1;
  border: 1px solid #eeca4e;
  padding: 7px;
  margin: 30px 0;
}

/* view profile page */

.profile-picture {
  float: left;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.profile {
  display: flex;
  text-align: justify;
}

.img-wrapper {
  margin: 35px;
  text-align: center;
}

.user-details {
  margin-right: 25%;
  padding-right: 15px;
}

.review-part {
  display: flex;
  margin-top: 40px;
}

.reviewer-image {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  margin-bottom: 7px;
}

.review-img-wrapper {
  margin-right: 10%;
  font-size: 17px;
}

.padding {
  padding: 5%;
}

.review-line {
  border-top: 1px solid #eeeeee;
  margin-top: 30px;
}

/* task box small */

.task-box-small-container {
  display: flex;
  flex-direction: column;
  width: 265px;
  margin: 2% 0;
}

.text-box-small-img {
  width: 100%;
}

.task-box-small-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* calendar */
.searchfilter-wrapper {
  margin: 20px 0 40px 0;
  display: flex;
  justify-content: center;
}

.searchfilter-wrapper select {
  padding: 0 12px;
  height: 32px;
  width: 170px;
}

.searchfilter-wrapper .select-wrapper {
  margin-right: 40px;
}


/* calendar overwrites */

.calendar-wrapper .rbc-event, .rbc-event.rbc-selected, .rbc-event:focus  {
  background-color: #006227;
}

.calendar-wrapper .rbc-event:focus, .rbc-event:hover {
  background-color: #6ed9a1;
}

.calendar-wrapper .rbc-toolbar-label {
  font-size: 26px;
  text-transform: uppercase;
}

.calendar-wrapper .rbc-active {
  background-color: #eeca4e !important;
}

.calendar-wrapper .rbc-toolbar button:hover {
  background-color: #eeca4e !important;
}

.calendar-wrapper .rbc-today {
  background-color: #f8e9b9;
}
