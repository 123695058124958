@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz');

body {
  margin: 0;
  padding: 0;
}


body, input, button {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  line-height: 1.3;
  font-size: 18px;
  color: #2e2f3e;
}

h1 {
  color: #2e2f3e;
  font-size: 35px;
}

h3 {
  text-transform: uppercase;
}

p, li {
  font-size: 20px;
}

a {
  color: #006227;
  text-decoration: none;
}

a:hover {
  color: #6ed9a1;
}

.App {
  background-color: white;
}

.main {
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.button-green  {
  display: inline-block;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  background-color: #006227;
  padding: 6px 25px;
  border-radius: 10px;
  min-width: 135px;
  margin-top: 20px;
}

.button-long {
  width: 40%;
}

.button-hollow {
  background-color: transparent;
  border: 1.5px solid #515151;
  color:#515151;
  font-weight: 580;
}

.button-hollow:hover {
  border: 1.5px solid #6bb09e;
}

.button-green:hover {
  background-color: #6bb09e;
  color: white;
}

.button-plain {
  color: #006227;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.grey-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 30px;
  background-color: #eaeaea;
}

.box-seventy-percent {
  width: 70%;
  margin: 5% auto;
  padding: 50px 50px 65px 50px;
}

/* utility */
.margin-top {
  margin-top: 6%;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.font-smaller-uppercase {
  font-size: 16px;
  text-transform: uppercase;
}

.green {
  color: #006227;
}

.link-grey {
  color: inherit;
}

.link-grey:hover {
  color: #006227;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 45px;
  padding-left: 10px;
  font-family: inherit;
  font-size: 18px;
  border-radius: 3.5px;
  outline: none;
  border: 1px solid #999999;
  padding: 3px 0 3px 15px;
}

select option {
  color: #666;
}

.category {
  background-color: #eeca4e;
  padding: 4px 12px;
  border-radius: 10px;
  width: 100px;
  border: 1px solid #eeca4e;
  margin-top: -15px;
  text-transform: uppercase;
  font-size: 16px;
}

/* header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.header-nav > ul {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.header-nav > ul > li > a {
  display: block;
  border: 1px solid black;
  padding: 8px;
  margin: 10px;
  min-width: 70px;

  color: black;
  text-decoration: none;
  text-align: center;
}

.header-nav > ul > .main-nav-highlight > a {
  background-color: #006227;
  color: white;
}

.header-nav > ul > .main-nav-highlight > a:hover {
  background-color: #6bb09e;
  border: 1px solid #6bb09e;
}

/* needs fixing that active link is yellow */
.header-nav a:hover, .header-nav a:active {
  background-color: #eeca4e;
  border: 1px solid #eeca4e;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content ul {
  list-style-type: none;
  padding-left: 0;
}

.dropdown-content a,
.dropdown-content button {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 20px;
}

.dropdown-content button {
  box-shadow: none;
  border: none;
  background: none;
  width: 100%;
}

.dropdown-content a:hover,
.dropdown-content button:hover {
  background-color: #eeca4e;
  border: none;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.svg-inline--fa, .icon {
  margin: 0 10px;
}

.icon-wrapper {
  width: 18%;
  min-width: 55px;
  margin-bottom: 5px;
}

/* footer */
.footer-wrapper {
  background-color: #f2f2f2;
  padding: 30px;
  margin-top: 30px;
}

.footer {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  color: #8c8c8c;
}

.footer nav ul {
  list-style-type: none;
  padding: 0;
}

.footer a {
  color: #8c8c8c;
}

.footer a:hover {
  color: #006227;
}